import React, { useMemo } from 'react'
import { FullPageLayout } from '../../components/FullPageLayout'
import { Container } from '../common/styled'
import routes from '../../helpers/routes'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { axiosInstance } from '../../utils/axios'
import PageTitle from '../../components/PageTitle'
import PageHeader from '../../components/PageHeader'
import { Button, Modal } from 'react-bootstrap'
import useAuth from '../../hooks/useAuth'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useRecipe } from '../recipes/utils'
import { formatAmount } from '../../utils/functions'

function useProductions() {
  const [productions, setProductions] = React.useState<any>([])
  const [loading, setLoading] = React.useState<boolean>(false)

  const fetchProductions = async () => {
    setLoading(true);
    console.log('cargando producciones')
    try {
      const response = await axiosInstance('/productions/get-productions-for-be-maked');
      setProductions(response.data);
    } catch (e: any) {
      toast.error(e.response.data);
    } finally {
      setLoading(false);
    }
  }

  React.useEffect(() => {
    fetchProductions();
  }, [])

  return { productions, loading, fetchProductions }
}

export default function ProductionsPage() {
  const { user } = useAuth();
  const { productions, loading, fetchProductions } = useProductions();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isRecipeModalOpen, setIsRecipeModalOpen] = React.useState(false);
  const [selectedProductionId, setSelectedProductionId] = React.useState<number | null>(null);
  const [selectedRecipeId, setSelectedRecipeId] = React.useState<number | null>(null);

  const openModal = (productionId: number) => {
    setSelectedProductionId(productionId);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedProductionId(null);
    setIsModalOpen(false);
  };

  const openRecipeModal = (recipeId: number) => {
    setSelectedRecipeId(recipeId);
    setIsRecipeModalOpen(true);
  };

  const closeRecipeModal = () => {
    setSelectedRecipeId(null);
    setIsRecipeModalOpen(false);
  }

  const productionState = (state: string) => {
    switch (state) {
      case 'draft':
        return 'Por hacer'
      case 'to_be_customized':
        return 'Por corregir y completar'
      case 'inUse':
        return 'En uso'
      default:
        return state
    }
  }

  const filteredProductions = useMemo(() => {
    // si el usuario es admin mostrar todas las producciones
    if (user && user.role === 'admin') return productions;
    // si el usuario no es admin mostrar solo las producciones que tengan production.recipe.acces === 'public'
    return productions.filter((production: any) => production.recipe.access === 'public');
  }, [productions, user])

  return (
    <FullPageLayout>
      <Container>
        <PageHeader>
          <PageTitle title="Producciones" />
          {user && user.role === 'admin' && (
            <Link className="btn btn-primary" to={routes.productions.newProduction}>Nueva Producción</Link>
          )}
        </PageHeader>
        {loading && <p>Cargando...</p>}
        {filteredProductions.length === 0 && <p>No hay producciones pendientes</p>}
        <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
          {filteredProductions.map((production: any) => (
            <div key={production.id}>
              <Production>
                {user && user.role !== 'almacenista' && production.state === 'draft' ?
                  <h5 onClick={() => openRecipeModal(production.recipe.id)}>{production.recipe.name}</h5>
                  :
                  <h5>{production.recipe.name}</h5>
                }
                <span>{productionState(production.state)}</span>
                {user && user.role !== 'almacenista' && production.state === 'draft' && <Button onClick={() => openModal(production.id)} className="btn btn-sm btn-warning">Completar Producción</Button>}
                {user && user.role === 'admin' && production.state === 'to_be_customized' && <Link className="btn btn-sm btn-warning" to={routes.productions.customizeProduction(production.id)}>Ver Producción</Link>}
              </Production>
            </div>
          ))}
        </div>
        {selectedProductionId !== null && (
          <CompletionProductionModal
            show={isModalOpen}
            close={closeModal}
            productionId={selectedProductionId}
            fetcher={fetchProductions}
          />
        )}
        {selectedRecipeId !== null && (
          <RecipeModal
            show={isRecipeModalOpen}
            close={closeRecipeModal}
            recipeId={selectedRecipeId}
          />
        )}
      </Container>
    </FullPageLayout>
  )
}

const Production = styled.div`
  border: 1px solid #ccc;
  padding: .8rem;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`

const CompletionProductionModal = ({ productionId, show, close, fetcher }: any) => {
  // el Modal va a tener una pregunta que diga si se ha usado la receta al 100% y un par de radios para seleccionar si o no
  // este formulario va a enviar un post a /productions/complete-production con el id de la producción y el valor del radio
  // usar un formulario para enviar los datos y que solo se pueda enviar cuando algún radio esté seleccionado
  // usar useForm para el formulario y si ningún radio está seleccionado deshabilitar el botón de submit
  // si el post es exitoso mostrar un toast de éxito y cerrar el modal
  // si hay un error mostrar un toast de error
  const { register, handleSubmit } = useForm();
  const [submiting, setSubmiting] = React.useState<boolean>(false);

  const onSubmit = async (data: any) => {
    try {
      setSubmiting(true);
      await axiosInstance.post('/productions/complete-production', { productionId, ...data });
      toast.success('Producción completada');
      await fetcher();
    } catch (e: any) {
      toast.error(e.response.data);
    } finally {
      setSubmiting(false);
      close();
    }
  }

  return (
    <Modal show={show} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>Completar Producción</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <div className='mb-2'>¿Se ha usado la receta al 100%?</div>
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="radio" id="completed" value="true" {...register('completed', { required: true })} />
              <label className="form-check-label" htmlFor="completed">Sí</label>
            </div>
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="radio" id="not-completed" value="false" {...register('completed', { required: true })} />
              <label className="form-check-label" htmlFor="not-completed">No</label>
            </div>
          </div>
          <div style={{ marginTop: "1em", display: "flex", gap: "1em" }}>
            <Button variant="secondary" onClick={close}>Cancelar</Button>
            <Button type="submit" className="btn btn-primary" disabled={submiting}>Completar Producción</Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}

const RecipeModal = ({ recipeId, show, close }: any) => {
  const { recipe, loading } = useRecipe(Number(recipeId));

  if (loading) {
    return (
      <Modal show={show} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Cargando...</Modal.Title>
        </Modal.Header>
      </Modal>
    )
  }

  return (
    <Modal show={show} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>{recipe.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ul>
          {recipe.items.map((ingredient: any) => (
            <li key={ingredient.id}>{ingredient.input.name} <b>{formatAmount(ingredient.amount)} {ingredient.unit}</b></li>
          ))}
        </ul>
      </Modal.Body>
    </Modal>
  )
}